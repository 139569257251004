






















































































import { BSpinner } from 'bootstrap-vue'
import { Component, Vue, Prop } from "vue-property-decorator";
import { Droit } from "@/api/models/authorization/droits/droit";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import { DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";
import AddUserFonctionModal from "./AddUserFonctionModal.vue";
import FonctionDroitsDetailsModal from "./FonctionDroitsDetailsModal.vue";
import EditUtilisateurDroits  from "./EditUtilisateurDroits.vue";
import { Fonction } from '@/api/models/authorization/fonctions/fonction';

@Component({
  components: {
    AddUserFonctionModal,
    FonctionDroitsDetailsModal,
    BSpinner,
    EditUtilisateurDroits
  },
  name: "edit-utilisateur-fonctions"
})
export default class EditUtilisateurFonctions extends Vue {
  @Prop({ required: true, default: () => [] }) listDroits!: Droit[]
  @Prop({ required: true, default: () => [] }) listFonctions!: Fonction[]
  @Prop({ required: true }) mainLoading!: boolean
  @Prop({ required: true }) identityId!: string

  selectedUtilisateur: DetailsUtilisateur | null = null;
  fonctionSelected: Fonction | null = null;

  modalLoading: boolean = false;

  async viewDetailsFonction(fonction: Fonction) {
    this.fonctionSelected = fonction;
    this.$bvModal.show("modal-fonction-droits-details");
  }

  async addUserFonction(newUserFonctionId: string) {
    this.modalLoading = true;
    await this.$http.myressif.identities.addFonction(this.identityId, newUserFonctionId).then(
      async (response: string) => {
        this.$emit('reload-fonctions')
        this.$bvModal.hide("modal-add-user-fonction");
        successAlert.fire({
          title: "Ajout d'une fonction",
          text: "Ajout effectué avec succès",
        });
      },
      (error: any) => {
        this.$bvModal.hide("modal-add-user-fonction");
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      }
    );
    this.modalLoading = false;
  }

    askDeleteFonction(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de retirer la fonction à l'utilisateur ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }


  async confirmDelete(id: string) {
    await this.$http.myressif.identities
      .deleteFonction(this.identityId, id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'une fonction",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(async () => {
        this.$emit('reload-fonctions');
      });
  }  
}
