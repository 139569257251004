






















































































































import { Planning, PlanningCreateModel } from "@/api/models/planning/planning";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import {
  BButton,
  BModal,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTbody,
  BTd,
  BAlert,
  BFormTextarea,
  BFormInput,
  BFormSelect,
  BTabs,
  BTab,
  BFormGroup,
  VBTooltip,
  BFormCheckbox,
  BFormDatepicker
} from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { Structure } from "@/api/models/structures/structure";
import { ContratCreateModel, Plage } from "@/api/models/utilisateurs/contrat";
import { TypeContratUtilisateur } from '@/api/models/enums/typeContratUtilisateur';

@Component({
  components: {
    BButton,
    BModal,
    BAlert,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BFormTextarea,
    BFormInput,
    BFormSelect,
    SearchableVueSelect,
    BTabs, 
    BTab,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormDatepicker
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
})
export default class CreateContrat extends Vue {
  @Prop({ required: true }) open!: Boolean;

  typeContratUtilisateur = TypeContratUtilisateur

  newContrat: ContratCreateModel = {
    plages: [{
      isPair: null,
      lunM: false,
      lunA: false,
      marM: false,
      marA: false,
      merM: false,
      merA: false,
      jeuM: false,
      jeuA: false,
      venM: false,
      venA: false,
    }],
    compteurs: []
  }

  listStructures = new PaginatedList<Structure>();

  selectedDay: string = "";
  displayMessage: boolean = false;

  userSelected: any = null;
  listUser: any[] = [];
  totalCountUser = 0;
  types: any = null

  isTwoWeeks = false

  plages: Plage[] = [
    {
      isPair: null,
      lunM: false,
      lunA: false,
      marM: false,
      marA: false,
      merM: false,
      merA: false,
      jeuM: false,
      jeuA: false,
      venM: false,
      venA: false,
    }
  ]

  // planning: PlanningCreateModel = {
  //   lunM: false,
  //   lunA: false,
  //   marM: false,
  //   marA: false,
  //   merM: false,
  //   merA: false,
  //   jeuM: false,
  //   jeuA: false,
  //   venM: false,
  //   venA: false,
  //   message: "",
  //   messageLunM: "",
  //   messageLunA: "",
  //   messageMarM: "",
  //   messageMarA: "",
  //   messageMerM: "",
  //   messageMerA: "",
  //   messageJeuM: "",
  //   messageJeuA: "",
  //   messageVenM: "",
  //   messageVenA: "",
  // };

  async created(params: any = null) {
    await this.loadUtilisateurs(params)
  }

  isTwoWeeksChanged() {
    if (this.isTwoWeeks) {
      this.newContrat.plages[0].isPair = true
      this.newContrat.plages.push({
        isPair: false,
        lunM: false,
        lunA: false,
        marM: false,
        marA: false,
        merM: false,
        merA: false,
        jeuM: false,
        jeuA: false,
        venM: false,
        venA: false,
      })
    } else {
      this.newContrat.plages = [this.newContrat.plages[0]]
    }
  }

  async loadUtilisateurs(params: any) {
    await this.$http.myressif.utilisateurs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        false,
        "Nom",
        false,
        this.types
      )
      .then((res: any) => {
        this.listUser = res.items.map(
          (x: DetailsUtilisateur) =>
            <DetailsUtilisateur>{
              ...x,
              completeLabel: `${x.nom} ${x.prenom}`,
            }
        );
        this.totalCountUser = res.totalCount;
      });
  }

  closeModal() {
    this.$emit("close");
  }
  
  cellClick(selectedDay: string) {
    this.selectedDay = selectedDay;
    this.displayMessage = true;
  }

  async submit() {
    await this.$http.myressif.utilisateurs
      .createContratOnUtilisateur(
        this.$route.params.id,
        this.newContrat
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            title: "Ajout d'un contrat",
            text: "Ajout effectué avec succès",
          });
          this.$emit("contrat-added");
          this.displayMessage = false;
          this.resetForm();
        },
        (error: any) => {
          if (error.response.data.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  resetForm() {
    this.plages = [{
      lunM: false,
      lunA: false,
      marM: false,
      marA: false,
      merM: false,
      merA: false,
      jeuM: false,
      jeuA: false,
      venM: false,
      venA: false,
    }];
  }

  async searchStructures(params: any) {
    if (!params || params?.reset) {
      this.listStructures = new PaginatedList<any>();
    }

    await this.$http.myressif.structures
      .paginatedList(
        params?.search ?? "",
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10
      )
      .then((response: PaginatedList<any>) => {
        this.listStructures = response;
      });
  }
}
