





















import { BSpinner } from 'bootstrap-vue'
import { Component, Vue, Prop } from "vue-property-decorator";
import { Droit } from "@/api/models/authorization/droits/droit";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import { DetailsUtilisateur } from '@/api/models/utilisateurs/utilisateur';
import { PaginatedList } from '@/api/models/common/paginatedList';
import { Fonction } from '@/api/models/authorization/fonctions/fonction';
import EditUtilisateurFonctions from './EditUtilisateurFonctions.vue';
import EditUtilisateurDroits from './EditUtilisateurDroits.vue';
import appSettings from "@/appSettings";


@Component({
  components: {
    BSpinner,
    EditUtilisateurFonctions,
    EditUtilisateurDroits
  },
  name: "edit-droits-fonctions"
})
export default class EditDroitsFonctions extends Vue {
  @Prop({ required: true }) initialUtilisateur!: DetailsUtilisateur;

  listDroits: PaginatedList<Droit> = new PaginatedList<Droit>();
  listFonctions: Fonction[] = []

  mainLoading: boolean = false;
  
  tenantId = appSettings.getTenantId();

  async mounted() {
    await this.loadDroits();
    await this.loadFonctions();
  }

  async loadDroits() {
    this.mainLoading = true;

    await this.$http.myressif.droits
      .paginatedList(1, 100)
      .then((response: PaginatedList<Droit>) => {
        this.listDroits = response;
        this.listDroits.items = this.listDroits.items.filter(x => x.tenantId == null || x.tenantId == this.tenantId)
        this.mainLoading = false;
      });
  }

  async loadFonctions() {
    this.mainLoading = true;
    await this.$http.myressif.identities
      .listFonctions(this.initialUtilisateur!.identity!.id)
      .then(
        (response: PaginatedList<Fonction>) => {
          this.listFonctions = response.items;
          this.mainLoading = false;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des fonctions de l'utilisateur",
          });
        }
      );
  }
  
}
