











































































import { Droit } from '@/api/models/authorization/droits/droit'
import { PaginatedList } from '@/api/models/common/paginatedList'
import { errorAlert } from '@/libs/sweetAlerts/alerts'
import {
  BButton, BModal, BForm, BFormInput, BFormGroup, BSpinner, BFormSelect, BCardTitle, BCardHeader, BTable, BCard, BFormCheckbox, VBTooltip
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { RightLevelFlag } from '@/api/models/enums/rightLevelFlag'
import { Fonction } from '@/api/models/authorization/fonctions/fonction'

@Component({
  components: {
    BButton, 
    BModal, 
    BForm, 
    BFormInput,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  }
})
export default class FonctionDroitsDetailsModal extends Vue {
  @Prop({ default: () => {} }) fonction!: Fonction;
  displayedFonction : any = null;

  loading = true;
  rightLevelFlag = RightLevelFlag;

  fields = [
    { 
      key: 'libelle',
      label: 'Libellé'
    },
    { 
      key: 'read',
      label: 'Lire'
    },
    { 
      key: 'update',
      label: 'Modifier'
    },
    { 
      key: 'create',
      label: 'Créer'
    },
    {
      key: 'delete',
      label: 'Supprimer'
    },
    {
      key: 'admin',
      label: 'Administration'
    }
  ]

  listDroits: Droit[] = [];

  async modalDisplayed() {
    this.displayedFonction = { ...this.fonction, droits : {}}
    this
      .fonction
      .droits
      .forEach(
        (droit: any) => this.displayedFonction.droits[droit.code] = droit.flag)

    await this.$http.myressif
      .droits
      .paginatedList(1,100)
      .then(
        (response: PaginatedList<Droit>) => {
          this.listDroits = response.items;
          this.loading = false;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des droits de l'application",
        });
      })
  }
}
