










































































































































































































































import {
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  BFormSelect,
  BModal,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTbody,
  BTd,
  BBadge,
} from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BSpinner,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BModal,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    BFormSelect,
    BTableSimple,
    BThead,
    BTr,
    BBadge,
    BTh,
    BTbody,
    BTd,
  },
})
export default class DetailsUtilisateurPlanning extends Vue {
  @Prop({ required: true }) planning!: any;
  @Prop({ required: true }) info!: any;
  @Prop({ required: true }) userId!: string;

  selectedDay: string = "";
  messageM: string = "";
  messageA: string = "";

  displayMessage: boolean = false;
  isUpdatePlanningActive: boolean = false;

  async created() {}

  cellClick(selectedDay: string, messageM: string, messageA: string) {
    this.selectedDay = selectedDay;
    this.messageM = messageM;
    this.messageA = messageA;
    this.displayMessage = true;
  }

  goToEntreprise(id: string) {
    this.$router.push({
      name: "details-adherent",
      params: { id: id },
    });
  }
}
