
































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { BAlert, BButton, BCard, BCol, BTab, BTabs, BPagination, BFormSelect, BRow, BTable,  } from "bootstrap-vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { Contrat } from "@/api/models/utilisateurs/contrat";
import CreateContrat from "./CreateContrat.vue";
import EditContrat from "./EditContrat.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BAlert,
    BCol,
    BCard,
    BTabs,
    BTab,
    BButton,
    BPagination,
    BFormSelect,
    BRow,
    BTable,
    CreateContrat,
    EditContrat
  },
})
export default class ListContrats extends Vue {
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];

  isAddNewContratModalActive = false
  isUpdateContratModalActive = false

  paginatedListContrats = new PaginatedList<Contrat>();

  selectedContratId = ""

  tableColumns = [
    { key: "dateDebut", label: "Date de début", sortable: true },
    { key: "structureLibelle", label: "Structure"},
    { key: "typeContratUtilisateur", label: "Type du contrat"},
    { key: "actions" },
  ];

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListContrats.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListContrats.items.length
    );
  }

  async created() {
    await this.loadContrats(null)
  }

  async loadContrats(params: any) {
    await this.$http.myressif.utilisateurs
      .getListContratsByUtilisateurId(
        this.$route.params.id,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((res: PaginatedList<Contrat>) => {
        this.paginatedListContrats = res;
      });
  }

  selectContrat(contratId: string) {
    this.selectedContratId = contratId;
    this.isUpdateContratModalActive = true;
  }

  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce contrat ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.utilisateurs
      .deleteContratUtilisateur(this.$route.params.id, id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un contrat",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.loadContrats(null);
      });
  }

}
