var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12",attrs:{"id":"authorization"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title"},[_vm._v("Droits "),(!_vm.$can('UTILISATEURS_DROITS:update'))?_c('span',[_vm._v("(lecture seule)")]):_vm._e()])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"ml-1 row"},[_c('b-form-checkbox',{staticClass:"herited-function mr-0",attrs:{"disabled":"","inline":"","checked":true}}),_vm._v(" Droit hérité d'une fonction   "),(_vm.$hasUserpref('DisplayToolTip', 'true'))?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],attrs:{"title":"Retrouver ici les droits inhérents à votre/vos fonction(s)","variant":"flat-primary"}},[_c('feather-icon',{attrs:{"icon":"InfoIcon"}})],1):_vm._e(),_c('b-table',{key:_vm.refreshIndex,staticClass:"mb-0 mt-1 tableDroits",attrs:{"striped":"","responsive":"","items":_vm.listDroits,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(libelle)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" "),(data.item.description)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",modifiers:{"hover":true,"v-secondary":true}}],staticClass:"align-text-top",attrs:{"title":data.item.description,"icon":"InfoIcon","size":"16"}}):_vm._e()]}},{key:"cell(read)",fn:function(data){return [_c('b-form-checkbox',{class:(_vm.droitsFonctions[data.item.code] & _vm.rightLevelFlag.Read) !=
                  0 ? 'herited-function' : '',attrs:{"disabled":(_vm.droitsFonctions[data.item.code] & _vm.rightLevelFlag.Read) != 0 ||
                !_vm.$can('UTILISATEURS_DROITS:update') ||
                data.item.disabledRead || 
                (data.item.ressifOnlyRead && !_vm.isTenantRessif),"inline":"","checked":(_vm.droitsFonctions[data.item.code] & _vm.rightLevelFlag.Read) !=
                  0 ||
                (_vm.droitsIdentite[data.item.code] & _vm.rightLevelFlag.Read) != 0},on:{"change":function (checked) { return _vm.change(data.item, checked, _vm.rightLevelFlag.Read); }}})]}},{key:"cell(update)",fn:function(data){return [_c('b-form-checkbox',{staticClass:"custom-checkbox-second",class:(_vm.droitsFonctions[data.item.code] & _vm.rightLevelFlag.Update) !=
                  0 ? 'herited-function' : '',attrs:{"disabled":(_vm.droitsFonctions[data.item.code] & _vm.rightLevelFlag.Update) != 0 ||
                !_vm.$can('UTILISATEURS_DROITS:update') ||
                (data.item.ressifOnlyUpdate && !_vm.isTenantRessif),"inline":"","checked":(_vm.droitsFonctions[data.item.code] & _vm.rightLevelFlag.Update) != 0 ||
                (_vm.droitsIdentite[data.item.code] & _vm.rightLevelFlag.Update) != 0},on:{"change":function (checked) { return _vm.change(data.item, checked, _vm.rightLevelFlag.Update); }}}),(data.item.noteUpdate)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",modifiers:{"hover":true,"v-secondary":true}}],attrs:{"title":data.item.noteUpdate}},[_c('span',[_vm._v(" Infos "),_c('feather-icon',{staticClass:"align-text-top",attrs:{"icon":"InfoIcon","size":"16"}})],1)]):_vm._e()]}},{key:"cell(create)",fn:function(data){return [_c('b-form-checkbox',{class:(_vm.droitsFonctions[data.item.code] & _vm.rightLevelFlag.Create) !=
                  0 ? 'herited-function' : '',attrs:{"disabled":(_vm.droitsFonctions[data.item.code] & _vm.rightLevelFlag.Create) != 0 ||
                !_vm.$can('UTILISATEURS_DROITS:update') ||
                (data.item.ressifOnlyCreate && !_vm.isTenantRessif),"inline":"","checked":(_vm.droitsFonctions[data.item.code] & _vm.rightLevelFlag.Create) !=
                  0 ||
                (_vm.droitsIdentite[data.item.code] & _vm.rightLevelFlag.Create) != 0},on:{"change":function (checked) { return _vm.change(data.item, checked, _vm.rightLevelFlag.Create); }}})]}},{key:"cell(delete)",fn:function(data){return [_c('b-form-checkbox',{class:(_vm.droitsFonctions[data.item.code] & _vm.rightLevelFlag.Delete) !=
                  0 ? 'herited-function' : '',attrs:{"disabled":(_vm.droitsFonctions[data.item.code] & _vm.rightLevelFlag.Delete) != 0 ||
                !_vm.$can('UTILISATEURS_DROITS:update') ||
                (data.item.ressifOnlyDelete && !_vm.isTenantRessif),"inline":"","checked":(_vm.droitsFonctions[data.item.code] & _vm.rightLevelFlag.Delete) !=
                  0 ||
                (_vm.droitsIdentite[data.item.code] & _vm.rightLevelFlag.Delete) != 0},on:{"change":function (checked) { return _vm.change(data.item, checked, _vm.rightLevelFlag.Delete); }}}),(data.item.noteDelete)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",modifiers:{"hover":true,"v-secondary":true}}],attrs:{"title":data.item.noteDelete}},[_c('span',[_vm._v(" Infos "),_c('feather-icon',{staticClass:"align-text-top",attrs:{"icon":"InfoIcon","size":"16"}})],1)]):_vm._e()]}},{key:"cell(admin)",fn:function(data){return [(data.item.allowAdmin)?_c('b-form-checkbox',{class:(_vm.droitsFonctions[data.item.code] & _vm.rightLevelFlag.Admin) !=
                  0 ? 'herited-function' : '',attrs:{"disabled":(_vm.droitsFonctions[data.item.code] & _vm.rightLevelFlag.Admin) != 0 ||
                !_vm.$can('UTILISATEURS_DROITS:update') || 
                (data.item.ressifOnlyAdmin && !_vm.isTenantRessif),"inline":"","checked":(_vm.droitsFonctions[data.item.code] & _vm.rightLevelFlag.Admin) !=
                  0 ||
                (_vm.droitsIdentite[data.item.code] & _vm.rightLevelFlag.Admin) != 0},on:{"change":function (checked) { return _vm.change(data.item, checked, _vm.rightLevelFlag.Admin); }}}):_vm._e()]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }