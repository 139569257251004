







































































import {
  BForm,
  BAvatar,
  BModal,
  BTable,
  BFormFile,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  BAlert,
  BLink,
  BTabs,
  BFormTextarea,
} from "bootstrap-vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { Planning } from "@/api/models/planning/planning";
import DetailsUtilisateurPlanning from "./editPlannings/DetailsUtilisateurPlanning.vue";
import ListContrats from "./contratUtilisateurs/ListContrats.vue"

@Component({
  components: {
    BForm,
    BButton,
    BCard,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BRow,
    BSpinner,
    BAlert,
    BLink,
    BTabs,
    BModal,
    Cropper,
    BTable,
    BAvatar,
    BFormFile,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    DetailsUtilisateurPlanning,
    ListContrats
  },
  name: "edit-utilisateur-materiel-mad",
})
export default class EditUtilisateurMaterielMad extends Vue {
  @Prop({ required: true }) initialUtilisateur!: DetailsUtilisateur;
  selectedUtilisateur: DetailsUtilisateur | null = null;

  async mounted() {
    this.resetModel();
  }

  resetModel() {
    this.selectedUtilisateur = JSON.parse(
      JSON.stringify(this.initialUtilisateur)
    );
  }

  update() {
    this.$emit("update", this.selectedUtilisateur);
  }
}
