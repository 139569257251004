




















































































































































































import {
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BSpinner,
  BTable,
  VBTooltip,
} from "bootstrap-vue";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Droit } from "@/api/models/authorization/droits/droit";
import { Fonction } from "@/api/models/authorization/fonctions/fonction";
import { RightLevelFlag } from "@/api/models/enums/rightLevelFlag";
import { Identity } from "@/api/models/authorization/identities/identity";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import appSettings from "@/appSettings";
import { EnumTenants } from "@/api/models/enums/enumTenants";

@Component({
  components: {
    BButton,
    BSpinner,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  name: "edit-utilisateur-droits",
})
export default class EditUtilisateurDroits extends Vue {
  @Prop({ required: true, default: () => [] }) listDroits!: Droit[];
  @Prop({ required: true, default: () => [] }) listFonctions!: Fonction[];
  @Prop({ required: true }) identityId!: string;
  @Prop({ required: true }) utilisateurId!: string;

  tenantId = appSettings.getTenantId();
  isTenantRessif = appSettings.getIsTenantRESSIF();

  tenants = EnumTenants;

  droitsFonctions: any = {};
  droitsIdentite: any = {};
  rightLevelFlag = RightLevelFlag;

  refreshIndex: any = 0;

  fields = [
    {
      key: "libelle",
      label: "Libellé",
    },
    {
      key: "read",
      label: "Lire",
    },
    {
      key: "update",
      label: "Modifier",
    },
    {
      key: "create",
      label: "Créer",
    },
    {
      key: "delete",
      label: "Supprimer",
    },
    {
      key: "admin",
      label: "Administration",
    },
  ];

  mounted() {
    this.mapDroits();
    this.loadIdentity();
  }

  @Watch("listFonctions")
  mapDroits() {
    this.droitsFonctions = {};
    this.listFonctions.forEach((f: Fonction) => {
      f.droits.forEach((d: any) => {
        this.droitsFonctions[d.code] != null
          ? (this.droitsFonctions[d.code] =
              this.droitsFonctions[d.code] | d.flag)
          : (this.droitsFonctions[d.code] = d.flag);
      });
    });
  }

  async loadIdentity() {
    await this.$http.myressif.identities.get(this.utilisateurId, true).then(
      (response: Identity) => {
        response.droits.forEach((d: any) => {
          this.droitsIdentite[d.code] != null
            ? (this.droitsIdentite[d.code] =
                this.droitsIdentite[d.code] | d.flag)
            : (this.droitsIdentite[d.code] = d.flag);
        });
      },
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      }
    );
  }

  async removeDroit(droit: any, column: any) {
    await this.$http.myressif.identities
      .deleteDroit(this.identityId, droit.id, column)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un droit",
            text: "Suppression effectuée avec succès",
          });
          this.mapDroits();
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }
  async addDroit(identityId: string, droit: any, flag: any) {
    await this.$http.myressif.identities
      .addDroit(identityId, droit.id, flag)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Ajout d'un droit",
            text: "Droit ajouté avec succès",
          });
          this.mapDroits();
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }

  async change(droit: any, checkOrNo: any, column: any) {
    if (this.tenantId == this.tenants.Focsie || this.tenantId == this.tenants.FocsieCentre || this.tenantId == this.tenants.Socialinter)
      return;
    
    if (checkOrNo) {
      this.$bvModal
        .msgBoxConfirm("Etes-vous sûr de vouloir ajouter ce droit ?", {
          title: "Confirmation d'ajout",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value === true) {
            this.addDroit(
              this.identityId,
              droit,
              this.droitsIdentite[droit.code] | column
            );
          } else {
            this.refreshIndex++;
            return;
          }
        });
    } else {
      this.$bvModal
        .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce droit ?", {
          title: "Confirmation de suppression",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value === true) {
            this.removeDroit(droit, column);
          } else {
            this.refreshIndex++;
            return;
          }
        });
    }
  }
}
