






































































































































































































































































































































import { Contrat, ContratEditModel } from "@/api/models/utilisateurs/contrat";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import {
  BButton,
  BModal,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTbody,
  BTd,
  BAlert,
  BFormTextarea,
  BFormInput,
  BTab,
  BTabs,
  BFormCheckbox,
  VBTooltip,
  BFormGroup,
  BFormDatepicker,
  BFormSelect
} from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { Structure } from "@/api/models/structures/structure";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { TypeContratUtilisateur } from '@/api/models/enums/typeContratUtilisateur';

@Component({
  components: {
    BButton,
    BModal,
    BAlert,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BFormTextarea,
    BFormInput,
    BTabs,
    BTab,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
    SearchableVueSelect,
    BFormGroup,
    BFormDatepicker,
    BFormSelect
  },directives: {
    "b-tooltip": VBTooltip,
  },
})
export default class EditContrat extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) open!: boolean;

  typeContratUtilisateur = TypeContratUtilisateur


  isTwoWeeks = false

  listStructures = new PaginatedList<Structure>();

  selectedDay: string = "";
  displayMessage: boolean = false;

  contrat: ContratEditModel | null = null

  async created() {
    await this.$http.myressif.utilisateurs
      .getContratById(this.$route.params.id, this.id)
      .then((res: Contrat) => {
        this.contrat = res;
        this.isTwoWeeks = this.contrat.plages.length > 1
      });
  }

  cellClick(selectedDay: string) {
    this.selectedDay = selectedDay;
    this.displayMessage = true;
  }

  isTwoWeeksChanged() {
    if (this.isTwoWeeks) {
      this.contrat!.plages[0].isPair = true
      this.contrat!.plages.push({
        isPair: false,
        lunM: false,
        lunA: false,
        marM: false,
        marA: false,
        merM: false,
        merA: false,
        jeuM: false,
        jeuA: false,
        venM: false,
        venA: false,
      })
    } else {
      this.contrat!.plages = [this.contrat!.plages[0]]
      this.contrat!.plages[0].isPair = null
    }
  }

  async updateContrat() {
    await this.$http.myressif.utilisateurs
      .updateContratUtilisateur(
        this.$route.params.id,
        this.contrat!.id,
        this.contrat
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            title: "Modification d'un contrat",
            text: "Modification effectuée avec succès",
          });
          this.$emit('reload');
          this.displayMessage = false;
        },
        (error: any) => {
          if (error.response.data.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  async searchStructures(params: any) {
    if (!params || params?.reset) {
      this.listStructures = new PaginatedList<any>();
    }

    await this.$http.myressif.structures
      .paginatedList(
        params?.search ?? "",
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10
      )
      .then((response: PaginatedList<any>) => {
        this.listStructures = response;
      });
  }

  closeModal() {
    this.$emit("close");
  }
}
