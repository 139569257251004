






































































































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  BCard,
  BRow,
  BCol,
  BSpinner,
  BTable,
  BButtonGroup,
  BButton,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";
import { DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BCard,
    BTable,
    BButton,
    BRow,
    BCol,
    BButtonGroup,
    BSpinner,
    BFormCheckbox,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  name: "view-utilisateur-administration",
})
export default class ViewUtilisateurAdministration extends Vue {
  @Prop({ required: true }) initialUtilisateur!: DetailsUtilisateur;

  selectedUtilisateur!: DetailsUtilisateur;
  tableColumns = [{ key: "nom", sortable: true }];

  userCanViewPrivateMember: boolean = false;

  async mounted() {
    this.resetModel();
    this.getAllowedViewPrivateUser();
  }

  async update() {
    await this.$http.myressif.utilisateurs
      .update(this.selectedUtilisateur.id, this.initialUtilisateur)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "L'utilisateur a bien été mis à jours",
          });
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  async getAllowedViewPrivateUser() {
    await this.$http.myressif.parametres
      .getByNom("fonctions_allowed_privatemember_access")
      .then((res: any) => {
        this.userCanViewPrivateMember = JSON.parse(res.valeur).some((x: string) => this.$store.state.user.fonctions_codes.includes(x))
      });
  }

  async unconfirmEmail() {
    this.initialUtilisateur.emailConfirmed = false;
    await this.$http.myressif.utilisateurs
      .update(this.selectedUtilisateur.id, this.initialUtilisateur)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "L'email à bien été déconfirmé",
          });
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  
  async askRemoveAccount() {
    this.$bvModal
      .msgBoxConfirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
        bodyClass: "font-small-3 text-center",
      })
      .then(async (value) => {
        if (value === true) {
          await this.removeAccount();
        } else {
          return;
        }
      });
  }

  async removeAccount() {
    await this.$http.myressif.utilisateurs
      .removeAccount(this.selectedUtilisateur.id)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "La compte a bien été supprimé",
          });
          this.$router.push({ name: 'utilisateurs' })
        },
        (error: any) => {
          console.log(error);
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: `Une erreur s'est produite lors de la suppression du compte: ${error.message}`,
            });
          }
        }
      );
  }
  async askConfirmEmail() {
    await this.$http.myressif.utilisateurs
      .askConfirmEmailManually(this.selectedUtilisateur)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "La demande à bien été envoyée",
          });
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  async askResetPassword() {
    await this.$http.myressif.utilisateurs
      .askResetPassword(this.selectedUtilisateur)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "La demande à bien été envoyée",
          });
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
  async confirmEmail() {
    await this.$http.myressif.utilisateurs
      .confirmEmail(this.selectedUtilisateur)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "Confirmation",
            text: "Votre email à bien été confirmé",
          });
          this.initialUtilisateur.emailConfirmed = true;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  async unlockAccount() {
    this.initialUtilisateur.lockoutEnd = null;
    await this.$http.myressif.utilisateurs
      .update(this.selectedUtilisateur.id, this.initialUtilisateur)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "Le compte a bien été débloqué",
          });
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  resetModel() {
    this.selectedUtilisateur = JSON.parse(
      JSON.stringify(this.initialUtilisateur)
    );
  }
}
