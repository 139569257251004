






























































































































































































































































import {
  BForm,
  BAvatar,
  BModal,
  BTable,
  BFormFile,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  BAlert,
  BLink,
  BTabs,
  BFormTextarea,
} from "bootstrap-vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { Planning } from "@/api/models/planning/planning";
import DetailsUtilisateurPlanning from "./editPlannings/DetailsUtilisateurPlanning.vue";
import ListContrats from "./contratUtilisateurs/ListContrats.vue"

@Component({
  components: {
    BForm,
    BButton,
    BCard,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BRow,
    BSpinner,
    BAlert,
    BLink,
    BTabs,
    BModal,
    Cropper,
    BTable,
    BAvatar,
    BFormFile,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    DetailsUtilisateurPlanning,
    ListContrats
  },
  name: "edit-utilisateur-infos",
})
export default class EditUtilisateurInfos extends Vue {
  @Prop({ required: true }) initialUtilisateur!: DetailsUtilisateur;
  file: File | null = null;
  canvas: HTMLCanvasElement | null = null;
  image: string | null = null;
  showCropper: boolean = false;
  selectedUtilisateur: DetailsUtilisateur | null = null;

  // Info pour les équipes
  tableColumns = [
    { key: "nom", sortable: true },
    { key: "responsable", sortable: true },
  ];
  userEquipes: {}[] | null = null;

  // Info pour les groupes
  userGroupes: {}[] | null = null;
  tableColumnsGroupes = [{ key: "nom", sortable: true }];

  // Info pour les compétences
  tableColumnsComp = [
    { key: "nom", sortable: true },
    { key: "note", sortable: true },
    { key: "categorie", sortable: true },
  ];
  // userCompetences: {}[] | null = null;

  // Info pour les plannings
  utilisateursPlannings: Planning[] = [];

  async loadPlannings() {
    if (this.$can('UTILISATEURS_PLANNING:read')) {
      await this.$http.myressif.utilisateurs
        .getPlanningByUserId(this.$route.params.id)
        .then((res: Planning[]) => {
          this.utilisateursPlannings = res;
        });
      }
  }

  async mounted() {
    await this.loadPlannings();
    this.resetModel();
    if (
      this.selectedUtilisateur &&
      this.selectedUtilisateur.photo &&
      this.selectedUtilisateur.sasToken
    ) {
      this.image =
        this.selectedUtilisateur.photo + this.selectedUtilisateur.sasToken;
    }
    if (this.selectedUtilisateur) {
      this.getEquipes(this.selectedUtilisateur.id);
      this.getGroupes(this.selectedUtilisateur.id);
      // this.getCompetences(this.selectedUtilisateur.id);
    }
  }

  async getEquipes(userId: string) {
    if (this.$can('UTILISATEURS:read') && this.$can('EQUIPES:read')) {
      await this.$http.myressif.utilisateurs.getEquipesByUserId(userId).then(
        (res: any) => {
          this.userEquipes = res;
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }

  async getGroupes(userId: string) {
    if (this.$can('UTILISATEURS:read') && this.$can('GROUPES:read')) {
      await this.$http.myressif.utilisateurs.getGroupesByUserId(userId).then(
        (res: any) => {
          this.userGroupes = res;
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }

  // async getCompetences(userId: string) {
  //   await this.$http.myressif.utilisateurs.getCompetencesByUserId(userId).then(
  //     (res: any) => {
  //       this.userCompetences = res;
  //     },
  //     (error: any) => {
  //       console.log(error);
  //     }
  //   );
  // }

  pixelsRestriction(restrictions: { maxWidth: number; maxHeight: number }) {
    return {
      maxWidth: restrictions.maxWidth,
      maxHeight: restrictions.maxHeight,
    };
  }

  async setCanvas(updated: { canvas: HTMLCanvasElement | null }) {
    this.canvas = updated.canvas;
  }

  async srcToFile(dataUrl: string) {
    var response = await fetch(dataUrl);
    var blob = await response.blob();
    var file = new File([blob], this.file!.name);
    return file;
  }

  async cropImage() {
    if (this.canvas) {
      var dataUrl = this.canvas.toDataURL();
      this.image = dataUrl;
      this.file = await this.srcToFile(dataUrl);
      this.showCropper = false;
      await this.updatePhotoUtilisateur();
    }
  }

  async cancelCropper() {
    if (this.selectedUtilisateur)
      this.image = this.selectedUtilisateur.photo + this.selectedUtilisateur.sasToken;
      this.showCropper = false;
  }

  async readFileAsync(file: Blob | File) {
    return new Promise<string | null>((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  @Watch("file")
  async processFile() {
    if (this.file) {
      try {
        const dataUrl = await this.readFileAsync(this.file);
        this.image = dataUrl;
      } catch (err) {
        console.log(err);
      }
    }
  }

  async updatePhotoUtilisateur() {
    this.$emit("updatePhoto", this.file);
  }

  resetModel() {
    this.selectedUtilisateur = JSON.parse(
      JSON.stringify(this.initialUtilisateur)
    );
  }

  update() {
    this.$emit("update", this.selectedUtilisateur);
  }
}
