






































































































































import {
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  BAlert,
  BLink,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";
import EditUtilisateurInfos from "./editTabs/EditUtilisateurInfos.vue";
import EditUtilisateurMaterielMad from "./editTabs/EditUtilisateurMaterielMad.vue";
import EditDroitsFonctions from "./editTabs/editDroits/Index.vue";
import ViewUtilisateurApplicationsAccess from "./editTabs/ViewUtilisateurApplicationsAccess.vue";
import ViewUtilisateurAdministration from "./editTabs/ViewUtilisateurAdministration.vue";
import { Identity } from "@/api/models/authorization/identities/identity";
import ListInformationsAdditionnelles from "../informationAdditionnelles/ListInformationsAdditionnelles.vue";
import { TypeEntiteEnum } from "@/api/models/enums/typeEntiteEnum";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BSpinner,
    BForm,
    BFormGroup,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    BAlert,
    BLink,
    BTabs,
    BTab,
    EditUtilisateurInfos,
    EditDroitsFonctions,
    EditUtilisateurMaterielMad,
    ViewUtilisateurApplicationsAccess,
    ListInformationsAdditionnelles,
    ViewUtilisateurAdministration,
  },
})
export default class EditUtilisateur extends Vue {
  selectedUtilisateur: DetailsUtilisateur | null = null;
  identityId: string | null = null;

  infosNotif: {infoMessage: string, variant: string}[] = []

  infoMessage: string = "";
  variant: string = "";

  mainLoading = true;

  required = required;

  entityType = TypeEntiteEnum.UTILISATEUR;

  async created() {
    await this.loadUtilisateur();
  }

  async loadUtilisateur() {
    await this.$http.myressif.utilisateurs
      .getById(this.$route.params.id)
      .then(async (res: DetailsUtilisateur) => {
        this.selectedUtilisateur = res;
        this.mainLoading = false;
        await this.getIdentity(this.selectedUtilisateur.id);
        if (this.selectedUtilisateur.lockoutEnd != null && this.selectedUtilisateur.lockoutNotExceeded && this.identityId && this.$can('UTILISATEURS:admin')) {
          this.infosNotif.push({infoMessage: "Attention : Utilisateur bloqué suite a trop de connexions infructueuses", variant: "danger"})
        }

        if (this.selectedUtilisateur.emailConfirmed == false) {
          this.infosNotif.push({infoMessage: "Attention : Email non confirmé", variant: "warning"})
        }
      });
  }

  async getIdentity(utilisateurId: string) {
    await this.$http.myressif.identities.get(utilisateurId).then(
      (response: Identity) => {
        this.selectedUtilisateur!.identity = response;
        this.identityId = response.id;
        this.mainLoading = false;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des détails de l'utilisateur",
        });
      }
    );
  }

  async update(val: any) {
    await this.$http.myressif.utilisateurs
      .update(this.$route.params.id, val)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "Modification d'un utilisateur",
            text: "Modification effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }

  async updatePhoto(val: any) {
    await this.$http.myressif.utilisateurs
      .updatePhoto(this.$route.params.id, val)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "Modification de la photo",
            text: "Modification effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }

  async askDeleteUtilisateur(id: string) {
    this.$bvModal
      .msgBoxConfirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
        bodyClass: "font-small-3 text-center",
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.utilisateurs.delete(id).then(
      async (response: string) => {
        successAlert.fire({
          title: "Suppression d'un utilisateur",
          text: "Suppression effectuée avec succès",
        });
        await this.goToList();
      },
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      }
    );
  }
  goToList() {
    this.$router.push({ name: "utilisateurs" });
  }
}
