


































































































import {
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  BAlert,
  BLink,
  BTabs,
  VBTooltip,
} from "bootstrap-vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { FonctionRessifnetFastt } from "@/api/models/ressifnetFastt/FonctionRessifnetFastt";
import appSettings from "@/appSettings";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BSpinner,
    BForm,
    BFormGroup,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    BAlert,
    BLink,
    BTabs,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  name: "view-utilisateur-applications-access",
})
export default class ViewUtilisateurApplicationsAccess extends Vue {
  @Prop({ required: true }) initialUtilisateur!: DetailsUtilisateur;

  listRessifnetFASTTFonction: FonctionRessifnetFastt[] = [];

  allowAddRessifnetFastt = appSettings.getAllowAddRessifnetFastt()
  selectedUtilisateur: DetailsUtilisateur | null = null;

  async created() {
    this.resetModel();
    await this.searchFonctionRessifnetFastt()
  }

  resetModel() {
    this.selectedUtilisateur = JSON.parse(
      JSON.stringify(this.initialUtilisateur)
    );
  }

  checkboxChange(utilisateur: DetailsUtilisateur, event: any) {
    if (event) {
      this.addAccesUtilisateur(utilisateur);
    } else {
      this.removeAccesUtilisateur(utilisateur);
    }
  }

  async addAccesUtilisateur(utilisateur: DetailsUtilisateur) {
    if (!this.selectedUtilisateur) return;
    await this.$http.myressif.utilisateurs
      .updateAcces(utilisateur.id, this.selectedUtilisateur)
      .then(
        async (response: any) => {
          successAlert.fire({
            title: "Ajout d'un accès",
            text: "Ajout effectué avec succès",
          });
        },
        (error: any) => {
          errorAlert.fire({
            title: "Oops",
            text: error.message,
          });
        }
      );
  }

  async removeAccesUtilisateur(utilisateur: DetailsUtilisateur) {
    if (!this.selectedUtilisateur) return;
    await this.$http.myressif.utilisateurs
      .updateAcces(utilisateur.id, this.selectedUtilisateur)
      .then(
        async (response: any) => {
          successAlert.fire({
            title: "Suppression d'un accès",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          errorAlert.fire({
            title: "Oops",
            text: error.message,
          });
        }
      );
  }

  async searchFonctionRessifnetFastt(){
    await this.$http.ressifnetFastt.fonctions.listFonction()
      .then((response: FonctionRessifnetFastt[]) => {
        this.listRessifnetFASTTFonction = response
      })
  }

}
